import img1 from "../../assets/images/about-1.jpg";
import img2 from "../../assets/images/about-2.jpg";
import img3 from "../../assets/images/about-3.jpg";

const images = [
  {
    src: img1,
    height: 1500,
    width: 1394,
  },
  {
    src: img2,
    height: 1500,
    width: 1003,
  },
  {
    src: img3,
    height: 1169,
    width: 1750,
  },
];

export { images };
