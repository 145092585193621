import "./home.scss";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";

import axios from "../../axiosConfig.jsx";
import { filterAccessibleEventsNYC } from "../../util/timeZoneFormatting.jsx";

import {
  convertDateReadability,
  convertMilitaryTime,
} from "../../util/formatting.jsx";

// component imports
import Header from "../../components/Header/Header.jsx";
import homeVideo from "../../assets/images/home-video.mp4";
import homeImage from "../../assets/images/home.jpg";
import Footer from "../../components/Footer/Footer.jsx";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [homeMedia, setHomeMedia] = useState(null);
  const [mediaReady, setMediaReady] = useState(false);
  const [futureEvents, setFutureEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const containerRef = useRef(null);
  const homeImageRef = useRef(null);
  const homeVideoRef = useRef(null);
  const timelineRef = useRef(null);
  const splitTextRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get("/events/get-all");
        let events = response.data;

        // filter events to only show future events that are set to public
        const filterPublicEvents = true;
        const futureEvents = filterAccessibleEventsNYC(
          events,
          filterPublicEvents
        );

        // Sort events in descending order (latest dates first)
        const sortedFutureEvents = futureEvents.sort((a, b) => {
          const dateA = new Date(`${a.date}`);
          const dateB = new Date(`${b.date}`);
          return dateA - dateB;
        });

        setFutureEvents(sortedFutureEvents);
        setEventsLoaded(true);
      } catch (error) {
        const errorData = error.response ? error.response.data : error.message;
        console.error("There was an error fetching the data:", errorData);
        // Still set eventsLoaded to prevent blocking the animation
        setEventsLoaded(true);
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      if (newIsMobile !== isMobile) {
        setIsMobile(newIsMobile);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  useEffect(() => {
    setMediaReady(false);

    // Pre-load the appropriate media
    if (isMobile) {
      const img = new Image();
      img.src = homeImage;
      img.onload = () => {
        setHomeMedia(homeImageRef.current);
        setMediaReady(true);
      };
      img.onerror = () => {
        console.error("Failed to load home image");
        setMediaReady(true); // Still set ready to prevent blocking
      };
    } else {
      const video = homeVideoRef.current;
      if (video) {
        video.load();
        const handleCanPlay = () => {
          setHomeMedia(video);
          setMediaReady(true);
        };
        video.addEventListener("canplaythrough", handleCanPlay);
        // If video is already loaded
        if (video.readyState >= 3) {
          handleCanPlay();
        }
        return () => video.removeEventListener("canplaythrough", handleCanPlay);
      }
    }
  }, [isMobile]);

  useGSAP(() => {
    if (!containerRef.current || !mediaReady || !eventsLoaded || !homeMedia)
      return;

    const textContainer = document.querySelector(".home-text-container");
    const textHeight = textContainer.scrollHeight;

    // Store timeline reference
    timelineRef.current = gsap.timeline({
      delay: 0.25,
    });

    // Create the animation sequence
    timelineRef.current
      .set(".home-text", {
        opacity: 0,
      })
      .set(homeMedia, {
        opacity: 0,
      })
      .to(homeMedia, {
        opacity: 1,
        duration: 0.25,
      })
      .to(".home-media-container", {
        delay: 0.1,
        minHeight: "0",
        duration: 0.5,
      })
      .to(
        ".home-logo-container img",
        {
          opacity: 1,
          duration: 0.5,
        },
        "<"
      )
      .to(
        ".home-text-container",
        {
          height: textHeight,
          duration: 0.5,
          maxHeight: "100%",
        },
        "<"
      )
      .to(
        ".home-media-container",
        {
          height: `calc(100svh - ${textHeight}px)`,
          duration: 0.5,
          ease: "sine.out",
        },
        "<"
      )
      .to(".wrapper-line-top, .wrapper-line-bottom", {
        width: () => (isMobile ? "50%" : "300px"),
        duration: 0.5,
      })
      .to(
        ".home-text",
        {
          duration: 0.75,
          opacity: 1,
          y: 0,
        },
        "<"
      )
      .to(
        ".home-event-container",
        {
          opacity: 1,
          duration: 0.25,
        },
        "<"
      )
      .add(() => {
        if (futureEvents.length > 0) {
          gsap.set(".home-event-item", {
            opacity: 0,
            y: 25,
          });

          return gsap.to(".home-event-item", {
            opacity: 1,
            duration: 0.5,
            stagger: 0.15,
            y: 0,
            onComplete: () => {
              gsap.to(".home-button-wrapper", {
                opacity: 1,
                duration: 0.25,
              });
            },
          });
        } else {
          return gsap.to(".home-no-events", {
            opacity: 1,
            duration: 0.5,
          });
        }
      }, "<");

    // Play immediately since we're already checking conditions at the start
    timelineRef.current.play();

    // Cleanup function
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
      }
    };
  }, [mediaReady, eventsLoaded, homeMedia]);

  return (
    <div>
      <Header />
      <div className="home-container" ref={containerRef}>
        <div className="home-media-container">
          {isMobile ? (
            <img
              ref={homeImageRef}
              src={homeImage}
              alt="DOBO NYC - Modern Filipino Food"
              className="home-image"
              onLoad={() => setMediaReady(true)}
            />
          ) : (
            <video
              ref={homeVideoRef}
              src={homeVideo}
              autoPlay
              playsInline
              muted
              loop
              preload="auto"
              className="home-video"
              alt="DOBO NYC - Modern Filipino Food"
              onLoadedData={() => setMediaReady(true)}
            />
          )}
          <div className="home-logo-container">
            <img src={logo} alt="DOBO NYC - Modern Filipino Food" />

            <div className="home-event-container">
              {futureEvents.length > 0 ? (
                <>
                  <h2 className="home-event-title">Upcoming Events:</h2>
                  <div className="home-event-list">
                    {futureEvents.map((event, index) => (
                      <div key={`event-${index}`} className="home-event-item ">
                        <h3>
                          {convertDateReadability(event.date)} at{" "}
                          {convertMilitaryTime(event.time)}
                          {event.seatsRemaining === 0 ? " - SOLD OUT" : ""}
                        </h3>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <h2 className="home-no-events">
                  Stay tuned for upcoming events!
                </h2>
              )}
            </div>
            {futureEvents.length > 0 && (
              <div className="home-button-wrapper">
                <Link to="/reservations">
                  <button
                    className="home-button"
                    aria-label="Navigate to the Reservations page"
                  >
                    BOOK NOW
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="home-text-container">
          <div className="container">
            <div className="home-text-wrapper">
              <div className="wrapper-line-top" />
              <h2 className="home-text">
                A unique Filipino dining experience, featuring time-honored
                recipes elevated to new heights.
              </h2>
              <div className="wrapper-line-bottom" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
