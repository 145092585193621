import "./about.scss";
import { useState, useEffect, useRef } from "react";
// component imports
import PageTitle from "../../components/PageTitle/PageTitle.jsx";
import { images } from "./imageArray.js";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// image imports
import aboutImage from "../../assets/images/about.jpg";

import usePageScroll from "../../animation-hooks/pageScroll.js";
import useFadeIn from "../../animation-hooks/fadeIn.js";
import LogoAnimation from "../../components/LogoAnimation/LogoAnimation.jsx";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);

const About = () => {
  const containerRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [index, setIndex] = useState(-1);
  const [maxPhotos, setMaxPhotos] = useState(window.innerWidth <= 1024 ? 2 : 3);
  useFadeIn(".page-container", 1, 0, aboutImage);
  usePageScroll();

  useEffect(() => {
    const handleImagesLoaded = () => {
      const images = containerRef.current?.querySelectorAll(
        ".react-photo-album img"
      );
      if (!images) return;

      let loadedImages = 0;
      const totalImages = images.length;

      images.forEach((img) => {
        if (img.complete) {
          loadedImages++;
        } else {
          img.onload = () => {
            loadedImages++;
            if (loadedImages === totalImages) {
              setImagesLoaded(true);
            }
          };
        }
      });

      if (loadedImages === totalImages) {
        setImagesLoaded(true);
      }
    };

    // Check images after a short delay to ensure PhotoAlbum has rendered
    setTimeout(handleImagesLoaded, 100);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMaxPhotos(window.innerWidth <= 1024 ? 2 : 3);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="page-container" ref={containerRef}>
        <div className="page-title-container">
          <div className="splash-image-container">
            <div
              className="splash-image"
              style={{ backgroundImage: `url(${aboutImage})` }}
            >
              &nbsp;
            </div>

            <div className="logo-animation-container">
              <LogoAnimation />
            </div>
          </div>
          <PageTitle title={"About"} />
        </div>

        <div className="container">
          <div className="page-content">
            <div className="about-container">
              <div className="about-quote-wrapper">
                <h3>
                  "Filipino food is a cuisine of contrasts-bold yet subtle, rich
                  yet delicate, familiar yet surprising."{" "}
                  <span>- Claude Tayag</span>
                </h3>
              </div>
              <div className="about-image-container">
                <PhotoAlbum
                  photos={images}
                  onClick={({ index }) => setIndex(index)}
                  layout="rows"
                  rowConstraints={{
                    maxPhotos: maxPhotos,
                  }}
                />
              </div>
              <p>
                Sean's culinary journey, inspired by his upbringing in NYC and
                Texas, reflects a deep connection to Filipino traditions and a
                passion for exceptional food. After cooking for 15 years and
                experiencing the highs and lows of fine dining, Sean founded
                Dobo as a tribute to his heritage, aiming to combine his
                culinary skills with a mission to highlight Filipino food. Join
                us for a special evening of elevated Filipino dishes and
                heartfelt hospitality, where each meal is crafted to make you
                feel like family.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Thumbnails]}
      />
    </>
  );
};

export default About;
